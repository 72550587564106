export const footerItems = {
  column1: [
    { label: "Corporates", path: "/corporates" },
    { label: "Forex Consultants", path: "/forex-consultants" },
    { label: "Banks", path: "/banks" },
    // { label: "Pricing", path: "/Pricing" },
  ],
  column2: [
    { label: "LinkedIn", url: "https://www.linkedin.com/company/finvisagebyapexft" },
  ],
  column3: [
    { label: "Pitch Deck",link_label:"Footer_terms_link", url: "https://finvisageimages.s3.ap-south-1.amazonaws.com/Finvisage_Pitch_Deck.pdf" },

    { label: "Terms & Conditions",link_label:"Footer_terms_link", url: "https://finvisageimages.s3.ap-south-1.amazonaws.com/Finvisage_Terms_and_Conditions.pdf" },
    { label: "Privacy Policy",link_label:"Footer_privacy_link", url: "https://finvisageimages.s3.ap-south-1.amazonaws.com/Finvisage_privacy_policy.pdf" },
    { label: "Refund Policy",link_label:"Footer_refund_link", url: "https://finvisageimages.s3.ap-south-1.amazonaws.com/Finvisage_Refund_Policy.pdf" },
    { label: "YouTube",link_label:"Footer_Youtube_link", url: "https://www.youtube.com/@finvisagebyapexfinancialte1656" },
  ],
};

export const HeaderItems = {
  column1: [
    { label: "Corporates",link_label:"Header_Corporates_link", path: "/corporates" },
    { label: "Forex Consultants",link_label:"Header_fx_link", path: "/forex-consultants" },
    { label: "Banks",link_label:"Header_banks_link", path: "/banks" },
    // { label: "Pricing",link_label:"Header_banks_link", path: "/Pricing" },
  ],
};

export const pricingitems = [
  {
    imgSrc: "/image/rightTick.webp",
    text: "Create and set up your workspace in two minutes.",
  },
  {
    imgSrc: "/image/rightTick.webp",
    text: "Add credits to your account from anywhere in the world.",
  },
  {
    imgSrc: "/image/rightTick.webp",
    text: "Add the derivative structures of your choice & start pricing.",
  },
  {
    imgSrc: "/image/rightTick.webp",
    text: "Forwards, Options, IRS & CCS Swaps are available.",
  },
  {
    imgSrc: "/image/rightTick.webp",
    text: "No fixed-term contracts. Pay as you use.",
  },
];




  export const CorporateHomePageData = {
    imgSrc: "/image/transparentLogo.webp",
    service: "For Corporates",
    imgSrc2: "/image/corporateCarousal.webp",
    texts: [
      "Do your imports & exports have an FX risk? ",
      "Would you like to know the fair price of your FX derivative deals? ",
      "Are you interested in knowing the value of IRS and CCS contracts before dealing? ",
      // "What if you could use a pricer without signing up for an expensive, long-term contract with a terminal provider?",
      "Step into financial clarity with us.",
     
    ],
  };

  export const ForexConsultantsHomePageData = {
    imgSrc: "/image/transparentLogo.webp",
    service: "For FX Consultants",
    imgSrc2: "/image/fxConsultantCarousal.webp",
   
    texts: [
      "Ready to stand out in a crowded field?",
      "Looking to gain a competitive edge with your clients? ",
      "Would you like to be the consultant of choice to clients?",
      "Your blueprint for customer delight.",
     
    ],
  };
  export const BanksHomePageData = {
    imgSrc: "/image/transparentLogo.webp",
    service: "For Banks",
    imgSrc2: "/image/bankCarousal.webp",
   
    texts: [
      "Do you want to empower your treasury sales teams with individual derivative pricers? ",
      "Looking to boost your team's hit ratio?",
      "Would you like to lower your costs of expensive market terminals? ",
      "Your derivatives solutions, simplified.",
    ],
  };


    export const testimonialsData = [
    {
      quote:'Banks have been built around compliance, and Fintechs are built around convenience. So, while we have seen a lot of successful Fintech companies, findings indicate that till about 2017-18, Fintechs have been more active in the retail and payments space. This decade is, however, seeing the growing influence of Fintech in the Corporate Wholesale Banking space, including Trade, Treasury, and Investment Banking. Banks and Fintechs will have to collaborate as a team to work for Corporate Treasuries to solve their problems together. Finvisage, both the product and team are known to me and I can endorse both. They are destined for bigger things soon. More power to you guys. Cheers.',
  
      name: 'Brejesh Chalill',
      position: 'Head of Treasury Markets Sales',
      company: 'Axis Bank',
    },
   
    {
      quote:'As the CEO of Cogencis, ensuring our clients have access to accurate, robust financial tools is essential to our success. Finvisage’s Derivative Pricer has been an invaluable addition to our terminal, delivering precise and fast pricing solutions that our clients can rely on. Its integration into our platform has been seamless, and we have received consistently positive feedback from users who rely on its accuracy in dynamic market conditions. Finvisage has proven to be a trusted partner, helping us elevate our product offering, and we are thrilled with the impact it has had on our clients decision-making and trading efficiency.',
      name: 'Aniruddha Chatterjee',
      position: 'Managing Director & CEO',
      company: 'NSE Cogencis',
    },
    {
      quote:'Arshdeep Jindal, Vishal Kapadia & Jayanth Ravindran, the trio are a lethal combination. The best thing about the team is that they always talk about the solution to a problem rather than the problem itself. Finvisage is a fantastic derivatives pricing tool that can cater to all segments of corporate treasury in India and abroad.',
      name: 'Akhilesh Dubey',
      position: 'Assistant Vice President Derivatives Structuring & Sales',
      company: 'Axis Bank',
    },
    {
      quote:'I would very much recommend that other companies consider utilising this system within their own business and look forward to continuing our relationship working together.',
      name: 'Tom Mitten',
      position: 'Statutory reporting and treasury manager',
      company: 'Nichols PLC UK',
    },
   
  ];


  export const scrollTop = () => {
   
    try {
      document.documentElement.scroll({
        top: 0,
        behavior: "smooth",
      });
    } catch (e) {
      document.body.scroll({
        top: 0,
        behavior: "smooth",
      });
    }
  };
  