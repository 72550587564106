export const staticData = {
    subSection:[
        
            {
                imgSrc: "/image/transparentLogo.webp",
                service: "For Corporates",
                texts: [
                  "You are about to enter into a FX deal with Forwards and Options.",
                  "Or, you are about to do an interest rate swap on a loan contract and you know that a few basis points can translate into a lot of money being left on the table. ",
                  "Would you like to know the fair value of your deal so that you can negotiate effectively a fair price with your counterparty? ",
                  "What if you could use a pricer without signing up for an expensive, long-term contract with a terminal provider?",
                  "What if you could choose to pay only when you needed to price?",
                  "Enter Finvisage.",
                ],
              },
              {
                imgSrc: "/image/transparentLogo.webp",
                service: "For Forex Consultants",
                texts: [
                  "You are a consultant to your corporate clients advising them on their FX and interest rate hedging needs. ",
                  "You may be guiding them on their choice of structures and timings of execution.  ",
                  "How do you tie them in with your firm by offering them a technological solution that they will need regularly?",
                  "Offer your clients a derivatives pricer through your Program ensuring they value the expertise and tools you bring to the table.",
                  "Enter Finvisage’s Program service built specifically for consultants.  ",
                ],
              },
              {
                imgSrc: "/image/transparentLogo.webp",
                service: "For Banks",
                texts: [
                  "Today only a handful of global investment banks can afford to give every Derivatives corporate salesperson a terminal for pricing of their own.",
                  "What about the rest?",
                 
                  "Finvisage is a web-based pricing app (also a world first phone app) priced at a fraction of a market terminal.",
                  "Current bank customers in India have rolled out Finvisage to all their salespeople. The UI has received high praise for being easy to use, clean and clutter-free.",
                  "Enter Finvisage",
                ],
              },
                {
                  imgSrc: "/image/transparentLogo.webp",
                  service: "Pricing packs",
                  texts: [
                    "Today only a handful of global investment banks can afford to give every Derivatives corporate salesperson a terminal for pricing of their own.",
                    "What about the rest?",
                   
                    "Finvisage is a web-based pricing app (also a world first phone app) priced at a fraction of a market terminal.",
                    "Current bank customers in India have rolled out Finvisage to all their salespeople. The UI has received high praise for being easy to use, clean and clutter-free.",
                    "Enter Finvisage",
                  ],
              },
            
        
    ],
    corporates:{
        videoLink:'4BcEAWLUqec',
    title:'Here’s a video showing how to sign up & start using our derivatives pricer'
    },
    fx:{
        videoLink:'R1L4qzJwmVQ',
        title:'Here’s how to sign up as a Forex consultant & start using our derivatives pricer'
},
    
    midSection:[
        {
            title:'Precise derivative valuations at your fingertips',
            QA:[
                {
                  question: "Do I need a credit card to sign up?",
                  answer:
                    "No, you can sign up without a credit card. We use a credits based pricing model where you add credits to your account and select the packs you need to start pricing.",
                  imgSrc: "/image/allFAQimgs/creditCardFAQ.webp",
                },
              
                {
                  question: "How do your prices benchmark against the large terminal players in the market?",
                  answer:
                    "Our prices have been checked and validated by many external parties. Our prices match exactly with the largest terminal provider globally. Our enterprise product is used by banks in their front offices and has been deployed after extensive validation.",
                  imgSrc: "/image/allFAQimgs/targetFAQ.webp",
                },
                {
                  question: "What structures and assets can I price?",
                  answer:
                    "We cover FX and interest rate derivatives structures. You can price short and long-term forwards, FX options, crosses, RFR swaps, and cross currency swaps. This covers 99% of the FX and IR derivatives markets today by notional." ,
              
                   subHeading:"If you would like to price equity options or price exotic derivatives, please get in touch with us."
              ,
                  imgSrc: "/image/allFAQimgs/structureFAQ.webp",
                },
                {
                  question: "Do I need a monthly license to use Finvisage?",
                  answer:
                    "No, you only need to add enough credits to cover your pricing requirements. We charge you based on your daily usage. Once your credits expire, your pricing ability is paused till credits are added again. ",
                  imgSrc: "/image/allFAQimgs/calenderFAQ.webp",
                },
              ]
        },
       
        {
            title:'Step Up Your Financial Game with Finvisage',
            QA:[
                { 
                    question: "How can I, as an FX consultant, sign up for Finvisage?",
                   answer:"You can sign up using the links available on this website and then reach out to us by filling in your information and submitting the form at the end of this page. We will reach out to you, complete the onboarding formalities, and tag you as an FX consultant.",
                   imgSrc: "/image/allFAQimgs/signupFAQ.webp",
                 },
               
                 {
                   question: "How do your prices benchmark against the large terminal players in the market?",
                   answer:"Our prices have been checked and validated by many external parties. Our prices match exactly with the largest terminal provider globally. Our enterprise product is used by banks in their front offices and has been deployed after extensive validation.",
                  
                   imgSrc: "/image/allFAQimgs/targetFAQ.webp",
                 },
                 {
                   question: "What structures and assets can I price?",
                   answer:"We cover FX and interest rate derivatives structures. You can price short and long-term forwards, FX options, crosses, RFR swaps, and cross currency swaps. This covers 99% of the FX and IR derivatives markets today by notional. ",
                   subHeading:"If you would like to price equity options or price exotic derivatives, please get in touch with us.",
                   imgSrc: "/image/allFAQimgs/structureFAQ.webp",
                 },
                 {
                     question: "How can I get my clients onboarded to Finvisage, but still manage their pricing abilities?",
                     answer:"FX consultants have exclusive access to the ‘Program’ feature, which allows you to define the pricing structures accessible, manage client lifecycles, view your own as well as your client’s billing details along with plenty of other features. ",
                   
                     imgSrc: "/image/allFAQimgs/clientFAQ.webp",
                   },
                   {
                     question: "How will I be billed for usage of the pricer by myself and my clients?",
                     answer:"We follow a monthly usage cycle for FX consultants and there are no fixed term contracts. Details can be shared during the signup process.",      
                     imgSrc: "/image/allFAQimgs/billFAQ.webp",
                   },
            ]
        },
        {
            title:'Market-Tested Valuations with Finvisage',
            QA:[
{
                    question: "How do your prices benchmark against the large terminal players in the market?",
                    answer:'Our prices have been checked and validated by many external parties. Our prices match exactly with the largest terminal provider globally. Our enterprise product is used by banks in their front offices and has been deployed after extensive validation.',
                    imgSrc: "/image/allFAQimgs/targetFAQ.webp",
                  },
                
                  {
                    question: "What structures and assets can I price?",
                    answer:"We cover FX and interest rate derivatives structures. You can price short and long-term forwards, FX options, crosses, RFR swaps, and cross currency swaps. This covers 99% of the FX and IR derivatives markets today by notional.",
                    subHeading:"If you would like to price equity options or price exotic derivatives, please get in touch with us.",
                    imgSrc: "/image/allFAQimgs/structureFAQ.webp",
                  },
                  {
                    question: "What other features do you have for banks & enterprises?",
                    answer:"In addition to the above mentioned structures, we can also price equity options, or price exotic derivatives. We have market data editing capabilities, portfolio valuation services, scenario based results and several other features available to give you the best pricing experience.",  
                    imgSrc: "/image/allFAQimgs/featureFAQ.webp",
                  },
            ]
        }
    ]
}